import { ESpacePrefix } from '~types/calendar';
import { generateRgbaContrastString, generateRgbaString } from '~utils/color';
import { useSpaceTypes } from '~hooks/useSpaceTypes';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';

export type SpaceTypesStylerProps = {
    children: React.ReactNode;
};

type SpaceStyleRule = { backgroundColor: string; borderColor: string; color: string; id: string };

export default function SpaceTypesStyler(props: SpaceTypesStylerProps): JSX.Element {
    const { children } = props;
    const { spaceTypes } = useSpaceTypes({});

    const styleRules = useMemo(() => {
        const rules = spaceTypes
            ?.map((spaceType) => {
                const spaceTypeRgba = spaceType?.theme?.colorPrimary ?? { r: 0, g: 0, b: 0, a: 1 };
                return [
                    {
                        borderColor: generateRgbaContrastString(spaceTypeRgba),
                        backgroundColor: generateRgbaString(spaceTypeRgba),
                        color: generateRgbaContrastString(spaceTypeRgba),
                        id: `${ESpacePrefix.SPACE_TYPE}-${spaceType._id}`,
                    },
                    ...(
                        spaceType.variants?.map((variant, index) => {
                            const spaceTypeVariantRgba =
                                variant?.theme?.colorPrimary ?? spaceTypeRgba;
                            return [
                                {
                                    borderColor: generateRgbaContrastString(spaceTypeVariantRgba),
                                    backgroundColor: generateRgbaString(spaceTypeVariantRgba),
                                    color: generateRgbaContrastString(spaceTypeVariantRgba),
                                    id: `${ESpacePrefix.SPACE_TYPE_VARIANT}-${variant._id}`,
                                },
                                ...(variant.spaces?.map((space) => ({
                                    borderColor: generateRgbaContrastString(spaceTypeVariantRgba),
                                    backgroundColor: generateRgbaString(spaceTypeVariantRgba),
                                    color: generateRgbaContrastString(spaceTypeVariantRgba),
                                    id: `${ESpacePrefix.SPACE}-${space._id}`,
                                })) ?? []),
                            ];
                        }) ?? []
                    ).flat(),
                ];
            })
            .flat();
        return rules;
    }, [spaceTypes]);

    return <SpaceTypesStyleWrapper styleRules={styleRules}>{children}</SpaceTypesStyleWrapper>;
}

const SpaceTypesStyleWrapper = styled.div<{
    styleRules?: SpaceStyleRule[];
}>`
    ${(props) =>
        props?.styleRules?.map(
            (rule) =>
                `.${rule.id} { color: ${rule.color}; background-color: ${rule.backgroundColor}; border-color: rgba(0,0,0,0.5); }`,
        ) ?? ''}
`;
